import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImg from "gatsby-background-image"

const ContactBackground = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "contact-bg.jpg" }) {
        childImageSharp {
          fixed(quality: 80, width: 1920) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImg
      Tag="section"
      fluid={data.desktop.childImageSharp.fixed}
      backgroundColor={`#2D58EC`}
    >
      {children}
    </BackgroundImg>
  )
}

export default ContactBackground
