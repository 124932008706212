import React from "react"
import { Link } from "gatsby"

// BOOTSTRAP & LAYOUT/SEO
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"

// IMAGES
import HeroBackground from "../components/images/heroBackground"
import AboutUsImage from "../components/images/aboutUsImage"
import MeetOurTeam from "../components/meetOurTeam"

//SERVICES IMAGES
import ServicesOne from "../components/images/servicesOne"
import ServicesTwo from "../components/images/servicesTwo"
import ServicesThree from "../components/images/servicesThree"
import ServicesFour from "../components/images/servicesFour"
import ServicesFive from "../components/images/servicesFive"
import ServicesSix from "../components/images/servicesSix"
// SVGs
import SalesManagement from "../images/salesmanagement.svg"
import Marketing from "../images/marketing.svg"
import Outsourcing from "../images/outsourcing.svg"
import SupplyAnalytics from "../images/supply.svg"
import Strategic from "../images/strategic.svg"
import Warehousing from "../images/global.svg"
//CONTACT IMAGES
import ContactFormImage from "../components/images/contactForm"
import ContactBackground from "../components/images/contactBackground"
import EmailImage from "../images/email.svg"
import PhoneImage from "../images/phone.svg"
import LocationImage from "../images/location.svg"

// CSS & CAROUSEL
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../components/carousel.css"
import homeStyles from "../components/homepage.module.css"

import BgPattern from "../components/images/bgPattern"

const Home = () => {
  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    // pauseOnDotsHover: true,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <Layout>
      <SEO title="Home" />
      {/* LANDING SECTION */}
      <HeroBackground>
        <Container className={homeStyles.hero}>
          <Row>
            <Col lg={9}>
              <h1>Elegant, Intelligent & Relevant <br /> Business Solutions</h1>
              <p>Strategic Alliances, Inc. (SAI) designs, develops, deploys, and delivers tailored, holistic, and integrated business solutions that balance today’s tactical limitations and realities with the future strategic imperative.</p>
              <Link to="/#contact" id={homeStyles.heroContact}>
                Contact Us
              </Link>
              <Link to="/what-we-do/" id={homeStyles.heroServices}>
                Learn More &#10141;
              </Link>
            </Col>
          </Row>
        </Container>
      </HeroBackground>
      {/* SERVICES SECTION */}
      <Container className={homeStyles.whatWeDo}>
        <Row>
          <Col>
            <p>Our Services</p>
            <h1>
              <span className={homeStyles.underlineCenter}>What We Do</span>
            </h1>
            <p>
              We develop and deploy tailored and integrated process, technologies, and capabilities that deliver successful business results fast – “Speed-To-Business-Results.”
              Our tailored solutions ensure profitable business growth and strategic success – <q>Business-By-Design, Not by default.</q>
            </p>
          </Col>
        </Row>
      </Container>
      <Slider
        {...settings}
        className={`homepage-slider ${homeStyles.services}`}
      >
        <ServicesOne>
          <Link to="/services/#sales">
            <div className={homeStyles.servicesOne}>
              <img src={SalesManagement} width="80" alt="Analytics" />
              <h3>Sales Management</h3>
              <p>
                Sales management is a process starting with “Generate Interest” and ending with “Deliver the Promise.”  We use a 5-phase sales process that builds trust and dramatically improves sales.
            </p>
            </div>
          </Link>
        </ServicesOne>
        <ServicesTwo>
          <Link to="/services/#demand">

            <div className={homeStyles.servicesTwo}>
              <img src={SupplyAnalytics} width="80" alt="Analytics" />
              <h3>Demand & Supply Chain Planning</h3>
              <p>
                Harmonizing accurately forecasted consumer demand with all elements of the supply chain pipeline from factory to the consumer’s home delivers optimal system-wide operations and profitability.
            </p>
            </div>
          </Link>

        </ServicesTwo>
        <ServicesThree>
          <Link to="/services/#warehousing">

            <div className={homeStyles.servicesThree}>
              <img src={Warehousing} width="80" alt="Analytics" />
              <h3>Warehousing & Distribution</h3>
              <p>
                We manage globally distributed inventories as well as providing 3PL services from our own 50,000 SF warehouse located in Rogers, AR.
            </p>
            </div>
          </Link>

        </ServicesThree>
        <ServicesFour>
          <Link to="/services/#outsourcing">

            <div className={homeStyles.servicesFour}>
              <img
                src={Outsourcing}
                width="80"
                alt="Business Process Outsourcing"
              />
              <h3>Business Process Outsourcing</h3>
              <p style={{ fontSize: "0.8rem" }}>
                We are your strategic partners.  We deliver scalable integrated solutions that manage the complexity of operations liberating you to focus on your core competencies and true innovation. “Never do yourself that which you can get someone else to do better, faster and cheaper.”
            </p>
            </div>
          </Link>

        </ServicesFour>
        <ServicesFive>
          <Link to="/services/#marketing">

            <div className={homeStyles.servicesFive}>
              <img src={Marketing} width="80" alt="Marketing" />
              <h3>Marketing & Product Development</h3>
              <p>
                Through highly evolved strategic partnership we have the ability dramatically reduce concept to delivery cycles and deliver Speed-To-Business Results
            </p>
            </div>
          </Link>

        </ServicesFive>
        <ServicesSix>
          <Link to="/services/#strategic">

            <div className={homeStyles.servicesSix}>
              <img src={Strategic} width="80" alt="Analytics" />
              <h3>Strategic Planning</h3>
              <p>
                Benchmarking, documenting, and direction setting for your business.  Objective, measurable, and achievable goal setting that incorporates realistic resource limits while substantially delivering the aspirational strategic goal.
            </p>
            </div>
          </Link>

        </ServicesSix>
      </Slider>
      {/* ABOUT US SECTION */}
      <div className={homeStyles.aboutUs}>
        <Container>
          <Row>
            <Col lg={5} className="order-2 order-sm-1">
              <AboutUsImage />
            </Col>
            <Col lg={7} className={`order-1 order-sm-2 ${homeStyles.aboutText}`}>
              <h2>
                <span className={homeStyles.underline}>About Us</span>
              </h2>
              <p>Founded in 1996, Strategic Alliances, Inc. (SAI), began as a product development, strategic sourcing, and supply chain consulting and business services company.  In 2004 SAI developed their Business Process Outsourcing (BPO) capabilities and acquired our first full services BPO client.  Today SAI is a professional services, consulting, and business process outsourcing (BPO) company that designs, develops, deploys, and delivers tailored, holistic, and integrated business solutions to worldwide small and midsized product and manufacturing companies desiring to sell and distribute their goods through the largest retail outlets in North America.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={homeStyles.separator}></div>
      {/* MEET OUR TEEM SECTION */}
      <BgPattern>
        <MeetOurTeam />
      </BgPattern>

      <ContactBackground>
        <Container className={homeStyles.contactUs} id="contact">
          <Row className={homeStyles.contactUsHeading}>
            <Col>
              <p>Send Us Message</p>
              <h1>
                <span className={homeStyles.underlineCenterDark}>
                  Contact Us
                </span>
              </h1>
            </Col>
          </Row>
          <Row className={homeStyles.contactUsForm}>
            <Col lg={8} className={homeStyles.contactUsInput}>
              <form
                name="Contact"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                action='/submit'
              >
                <input type="hidden" name="form-name" value="Contact" aria-label="contact" />
                <input type="hidden" name="bot-field" aria-label="bot-field"/>
                <input
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  required
                  aria-label="name"
                />
                <input
                  placeholder="Website/Company (Optional)"
                  type="text"
                  name="company"
                  aria-label="company"
                />
                <input placeholder="Email" type="email" name="email" required aria-label="email" />
                <textarea placeholder="Message" name="message" required aria-label="message" />
                <button id={homeStyles.contactSubmit} type="submit">
                  Contact Us
                </button>
              </form>
            </Col>
            <Col lg={4} style={{ padding: 0 }}>
              <ContactFormImage>
                <div className={homeStyles.contactFormBg}>
                  <h2>Info</h2>
                  <div className={homeStyles.contactInfo}>
                    <img src={LocationImage} alt="Location Marker" width="60" />
                    <div>
                      <h3>Find Us</h3>
                      <a href="https://www.google.com/maps/place/1703+N+13th+St,+Rogers,+AR+72756,+Сједињене+Државе/@36.3513889,-94.1355737,17z" target="_blank" rel="noopener noreferrer">
                        1703 N. 13th Rogers,
                        <br />
                        AR 72756
                      </a>
                    </div>
                  </div>
                  <div className={homeStyles.contactInfo}>
                    <img src={PhoneImage} alt="Location Marker" width="60" />
                    <div>
                      <h3>Call Us</h3>
                      <a href="tel:4792027000">479 202-7000</a>
                    </div>
                  </div>
                  <div className={homeStyles.contactInfo}>
                    <img src={EmailImage} alt="Location Marker" width="60" />
                    <div>
                      <h3>Mail Us</h3>
                      <a href="mailto:info@sainc.biz">info@sainc.biz</a>
                    </div>
                  </div>
                </div>
              </ContactFormImage>
            </Col>
          </Row>
        </Container>
      </ContactBackground>
    </Layout>
  )
}

export default Home
